import http from "@/utils/request";


// 获取门店列表
export function shopInfoApplyGrid(data) {
    return http({
        method: "post",
        url: "/fast/user/shopInfoApplyGrid/page.nd",
        data: data
    });
}

// 导出
export function exportData(data) {
  return http({
      method: "post",
      url: '/fast/user/shopInfoApplyGrid/export.nd',
      data:data,
      responseType: 'blob'  
  })
}

// 获取合作关系列表
export function getshopCooperateList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getDistributorShopCooperateList.nd",
    data: data
  });
}
// 作废
export function terminalActivityByIdFxshop(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fxshop/terminalActivityById.nd",
    data: data
  });
}







